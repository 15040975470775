import classes from "./Footer.module.css";

function Footer() {
  return (
    <>
      <div className={classes.contact}><p>Kontaktirajte nas</p></div>
      <footer className={classes.footer}>
        <div className={classes.border}>
          <p>
            Firma: <span>Enterijer Stanojlovic</span>
          </p>
          <p>
            PIB:<span>11455155</span>
          </p>
          <p className={classes.para}>
            <i className="fa-solid fa-house"></i>
            <span>
              <a
                href="https://maps.app.goo.gl/ftJiX3q91kjk2Dfv9"
                target="blank"
              >
                Mačkovac
              </a>
            </span>
          </p>
        </div>
        <div className={classes.border}>
          <p>
            <i className="fa-solid fa-phone"></i>
            <span>
              <a href="tel:+381694011169" target="blank">
                0694011169
              </a>
            </span>
          </p>
          <p>
            <i className="fa-solid fa-square-envelope"></i>
            <span>
              <a href="mailto:enterijer.stanojlovic@gmail.com" target="blank">
                enterijer.stanojlovic@gmail.com
              </a>
            </span>
          </p>
          <p>
            <i className="fa-brands fa-square-instagram"></i>
            <span>
              <a
                href="https://www.instagram.com/enterijer_stanojlovic?igsh=cTA0bzc1bm5meGpn"
                target="blank"
              >
                enterijer_stanojlovic
              </a>
            </span>
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
