export const images = {
  spavaca: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141254/namestaj-2.jpg",
        id: "spavaca 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141263/namestaj-34.jpg",
        id: "spavaca 3",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141264/namestaj-35.jpg",
        id: "spavaca 4",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141254/namestaj.jpg",
        id: "spavaca 5",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132952/image6_zb9ye2.jpg",
        id: "spavaca 6",
      },
    ],
    text: "Spavaće sobe po meri odražavaju udobnost i stil koji je u skladu sa vašim potrebama. Dizajniramo krevete, ormariće i garderobere koji stvaraju prijatan ambijent za odmor.",
    title: "Spavaće sobe",
  },
  dnevna: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141261/namestaj-26.jpg",
        id: "dnevna 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141261/namestaj-27.jpg",
        id: "dnevna 2",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132954/image16_xdc0u1.jpg",
        id: "dnevna 3",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132953/image11_wr1jgq.jpg",
        id: "dnevna 4",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132953/image10_wpjg0v.jpg",
        id: "dnevna 5",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132953/image4_r3gi4d.jpg",
        id: "dnevna 6",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132953/image3_mno7ik.jpg",
        id: "dnevna 7",
      },
    ],
    text: "Naši unikatni komadi nameštaja za dnevne sobe osmišljeni su da pruže udobnost i stil. Kreiramo sofe, stolove, police i TV komode po meri, tako da svaki element savršeno odgovara Vašem prostoru i potrebama.",
    title: "Dnevne sobe",
  },
  kuhinja: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141260/namestaj-22.jpg",
        id: "kuhinja 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141257/namestaj-13.jpg",
        id: "kuhinja 2",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141259/namestaj-18.jpg",
        id: "kuhinja 3",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141258/namestaj-15.jpg",
        id: "kuhinja 4",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132954/image19_akj5vg.jpg",
        id: "kuhinja 5",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132952/image0_vjvred.jpg",
        id: "kuhinja 6",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132952/image1_vubqxp.jpg",
        id: "kuhinja 7",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132952/image2_beaseb.jpg",
        id: "kuhinja 8",
      },
    ],
    text: "Enterijer Stanojlović kreira kuhinje po meri koje kombinuju funkcionalnost i estetiku. Naši dizajni prilagođeni su svakom prostoru, uz posebnu pažnju posvećenu optimizaciji prostora, visoko kvalitetnim materijalima i modernim dizajnerskim rešenjima.",
    title: "Kuhinje",
  },
  americki: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141262/namestaj-30.jpg",
        id: "americki 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141263/namestaj-33.jpg",
        id: "americki 2",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141255/namestaj-7.jpg",
        id: "americki 3",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141255/namestaj-6.jpg",
        id: "americki 4",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141257/namestaj-14.jpg",
        id: "americki 5",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141263/namestaj-32.jpg",
        id: "americki 6",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132954/image14_ezgq5u.jpg",
        id: "americki 7",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132954/image20_cuwpnr.jpg",
        id: "americki 8",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132954/image18_vmeibs.jpg",
        id: "americki 9",
      },
    ],
    text: "Američki plakari koje izrađujemo nude maksimalno iskorišćenje prostora sa elegantnim i praktičnim dizajnom. Naši ormari su idealni za svakoga ko želi uredan i organizovan prostor, uz širok izbor materijala i dodataka.",
    title: "Američki plakari",
  },
  standardni: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141259/namestaj-20.jpg",
        id: "standardni 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141260/namestaj-21.jpg",
        id: "standardni 2",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132955/image21_gsrbnc.jpg",
        id: "standardni 3",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132954/image15_egpqey.jpg",
        id: "standardni 4",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132953/image8_bcpnhi.jpg",
        id: "standardni 5",
      },
    ],
    text: "Za ljubitelje tradicionalnog stila, kreiramo klasične plakare po meri. Naš nameštaj  odlikuju sofisticirani dizajn i vrhunski kvalitet izrade, kako bi se savršeno uklopili u svaki dom.",
    title: "Standardni plakari",
  },
  predsoblje: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141262/namestaj-28.jpg",
        id: "predsoblje 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141262/namestaj-29.jpg",
        id: "predsoblje 2",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141256/namestaj-10.jpg",
        id: "predsoblje 3",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141254/namestaj-4.jpg",
        id: "predsoblje 4",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141261/namestaj-27.jpg",
        id: "predsoblje 5",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141261/namestaj-26.jpg",
        id: "predsoblje 6",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132954/image17_kiflha.jpg",
        id: "predsoblje 7",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132952/image5_b296y1.jpg",
        id: "predsoblje 8",
      },
    ],
    text: "Ukrasni paneli i oprema za predsoblja daju vašem domu prvi utisak. Pravimo jedinstvene panele i funkcionalne elemente za odlaganje, savršeno uklopljene u vas stil.",
    title: "Ukrasni paneli i predsoblja",
  },
  decija: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141256/namestaj-11.jpg",
        id: "decija 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141254/namestaj-3.jpg",
        id: "decija 2",
      },
    ],
    text: "Kreiramo dečije sobe koje su funkcionalne, sigurne i zabavne. Naši prilagođeni kreveti, ormari i radni stolovi pružaju idealno okruženje za igru, druženje i odrastanje.",
    title: "Dečije sobe",
  },
  trpezarija: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141263/namestaj-31.jpg",
        id: "trpezarija 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132953/image13_parvim.jpg",
        id: "trpezarija 2",
      },
    ],
    text: "Naši trpezarijski stolovi izrađeni su sa ciljem da budu centralni deo svakog doma. Svaki sto je unikatan, izrađen po meri, od kvalitetnih materijala i prilagođen vašim potrebama i stilu.",
    title: "Trpezarijski stolovi",
  },
  kupatila: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141258/namestaj-17.jpg",
        id: "kupatila 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141258/namestaj-16.jpg",
        id: "kupatila 2",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141259/namestaj-19.jpg",
        id: "kupatila 3",
      },
    ],
    text: "Naši elementi za kupatila uključuju moderne ormariće, police i panele koji unose red i estetiku u svaki prostor. Nudimo rešenja za sve veličine i stilove kupatila.",
    title: "Kupatila",
  },
  komode: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730222728/1730221662915_qmz7qf.jpg",
        id: "komode 1",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730222669/1730221662896_puagim.jpg",
        id: "komode 2",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730222399/1730221662827_horgqp.jpg",
        id: "komode 3",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730222440/1730221662844_owdkwn.jpg",
        id: "komode 4",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730141260/namestaj-23.jpg",
        id: "komode 5",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730222301/1730221662860_fclsnc.jpg",
        id: "komode 6",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1730222597/1730221662878_xiig2q.jpg",
        id: "komode 7",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132953/image9_nivsdm.jpg",
        id: "komode 8",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737132953/image7_drsqfg.jpg",
        id: "komode 9",
      },
    ],
    text: "Ormari i komode iz naše radionice osmišljeni su da zadovolje sve vaše potrebe za skladištenjem. Svaki komad je funkcionalan, izrađen od najkvalitetnijih materijala, i prilagođen vašem prostoru.",
    title: "Ormari i komode",
  },
  poslovni: {
    image: [
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737155655/image6_pcwizq.jpg",
        id: "poslovni 4",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737155654/image1_e7bsxk.jpg",
        id: "poslovni 5",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737155654/image4_tnvdbg.jpg",
        id: "poslovni 6",
      },
      {
        img: "https://res.cloudinary.com/drcbe5h2r/image/upload/v1737155654/image5_pmcmny.jpg",
        id: "poslovni 7",
      },
    ],
    text: "Opremamo poslovne prostore funkcionalnim i modernim nameštajem. Bez obzira na vrstu poslovanja, nudimo rešenja koja poboljšavaju produktivnost i čine radno okruženje prijatnim i profesionalnim.",
    title: "Poslovni prostor",
  },
};
